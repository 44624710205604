export const ModalWindowStyles = {
  width: "30rem",
  height: "25vh",
  border: "none",
  margin: "10rem auto",
};
export const StackStyles = {
  backgroundColor: "#504e4e",
  borderRadius: "5px",
  boxShadow: "0 4px 6px #5b5b5b",
};

export const TableHeaderStyles = {
  color: "#808080",
  fontFamily: "Roboto, Arial, sans-serif",
  paddingTop: 0,
};

export const TableBodyStyles = {
  color: "#fff",
  fontFamily: "Roboto, Arial, sans-serif",
};

export const PhotoContainerStyles = {
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  marginLeft: "15px",
  marginBottom: "10px",
};

export const PhotoBoxStyles = {
  width: "40px",
  height: "40px",
  border: "1px solid #fff",
  borderRadius: "5px",
  color: "#fff",
  alignItems: "center",
  fontSize: "11px",
  cursor: "pointer",
};

export const LinkStyles = {
  fontFamily: "italic",
  color: "#f1c40f",
  textDecoration: "underline",
  marginLeft: "10px",
  marginBottom: "10px",
  cursor: "pointer",
};

export const closeBtnStyles = {
  display: "flex",
  justifyContent: "right",
  alignItems: "center",
  padding: "5px",
  paddingBottom: 0,
};
