import React, { useEffect, useState } from "react";
import { MapContainer, Polygon, TileLayer } from "react-leaflet";
import { CRS, LatLngTuple } from "leaflet";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { CemeteryCoordinatesProps } from "../../models/ICemeteryMapProps";
import { calcCoordinates } from "../../utils/coordinateUtils";
import MapControls from "./mapControls/MapControls";
import { Box } from "@mui/material";
import ModalWindowOfPersonDates from "../ModalWindowOfPersonDates/ModalWindowOfPersonDates";
import {
  getCoordinatesToStore,
  getPeopleToStore,
  setSelectedPolygon,
} from "../../store/reducers/GraveReducer/actions";
import UpdateMapCenter from "./mapUdater/mapUpdater";

interface CemeteryMapProps {
  isMapModalOpen: boolean;
  onClick: () => void;
}

const ShortViewMap: React.FC<CemeteryMapProps> = ({
  isMapModalOpen,
  onClick,
}) => {
  const { selectedCemetery } = useAppSelector((state) => state.cemeteryReducer);
  const { selectedPolygonId, coordinatesValues, peopleDates } = useAppSelector(
    (state) => state.graveReducer
  );
  const dispatch = useAppDispatch();
  const [clickPolygon, setClickPolygon] = useState<number | null>(null);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [Hovered, setHovered] = useState(false);
  const [allowPolygonSelection, setAllowPolygonSelection] = useState(false);
  const handlePolygonClick = (cemeteryData: CemeteryCoordinatesProps) => {
    if (allowPolygonSelection) {
      dispatch(setSelectedPolygon(cemeteryData.id));
      setModalOpen(true);
    }
  };

  const closeModal = () => {
    setClickPolygon(null);
    setModalOpen(false);
  };

  const toggleFullScreen = () => {
    setAllowPolygonSelection((prev) => !prev);
    setIsFullScreen((prev) => !prev);
    if (isMapModalOpen) {
      onClick();
    }
  };

  const handleMapControlsHover = () => {
    setHovered(true);
  };

  const handleMapControlsLeave = () => {
    setHovered(false);
  };

  const mapStyle = isFullScreen
    ? {
        width: "100vw",
        height: "100vh",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        marginTop: "-2.1%",
        zIndex: 1000,
      }
    : { width: "58rem", height: "380px", margin: "0 auto" };

  const mapProps = isFullScreen
    ? {}
    : {
        dragging: false,
        doubleClickZoom: false,
        zoomControl: false,
      };

  const mapKey = isFullScreen ? "fullScreenMap" : "normalMap";

  const { latitude, longitude } = peopleDates?.coordinates || {
    latitude: 0,
    longitude: 0,
  };

  useEffect(() => {
    if (peopleDates) {
      dispatch(getCoordinatesToStore(peopleDates.cemeteryId));
    }
  }, [peopleDates?.cemeteryId, peopleDates, dispatch]);

  useEffect(() => {
    if (selectedPolygonId) {
      dispatch(getPeopleToStore(selectedPolygonId));
    }
  }, [selectedPolygonId, dispatch]);

  useEffect(() => {
    if (!isFullScreen) {
      setHovered(false);
    }
  }, [isFullScreen]);

  return (
    <Box
      sx={{ width: "100%" }}
      onMouseEnter={handleMapControlsHover}
      onMouseLeave={handleMapControlsLeave}
    >
      {latitude && longitude && coordinatesValues && (
        <Box
          id="map-container"
          style={{
            ...mapStyle,
            position: isFullScreen ? "fixed" : "relative",
            zIndex: 1,
          }}
        >
          <MapContainer
            key={mapKey}
            center={[latitude, longitude]}
            zoom={19}
            scrollWheelZoom={true}
            style={{ width: "100%", height: "100%" }}
            maxZoom={21}
            crs={CRS.EPSG3857}
            {...mapProps}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              maxNativeZoom={19}
              maxZoom={21}
            />
            {coordinatesValues &&
              coordinatesValues.map(
                (cemeteryData: CemeteryCoordinatesProps) => {
                  const rectangleCoordinates = calcCoordinates(
                    cemeteryData.coordinates
                  );
                  const latLngCoordinates: LatLngTuple[] =
                    rectangleCoordinates.map((coord) => [
                      coord.latitude,
                      coord.longitude,
                    ]);

                  return (
                    <Polygon
                      key={cemeteryData.id}
                      positions={latLngCoordinates}
                      pathOptions={{
                        color:
                          selectedPolygonId === cemeteryData.id
                            ? "#FFFF00"
                            : isFullScreen && clickPolygon === cemeteryData.id
                            ? "#FFFF00"
                            : "#fff",
                        weight: 2,
                      }}
                      eventHandlers={{
                        mouseover: () => setClickPolygon(cemeteryData.id),
                        mouseout: () => setClickPolygon(null),
                        click: () => handlePolygonClick(cemeteryData),
                      }}
                    />
                  );
                }
              )}
            <MapControls
              toggleFullScreen={toggleFullScreen}
              isFullScreen={isFullScreen}
              positionStyles={{
                opacity: Hovered ? 1 : 0,
                transition: "opacity 0.2s ease-in-out",
              }}
            />
            <UpdateMapCenter center={[latitude, longitude]} />
          </MapContainer>
        </Box>
      )}
      {peopleDates && (
        <ModalWindowOfPersonDates
          open={modalOpen}
          onClose={closeModal}
          id={peopleDates.id}
          cemeteryId={selectedCemetery && selectedCemetery.id}
          persons={peopleDates.persons}
          photos={peopleDates.photos}
        />
      )}
    </Box>
  );
};

export default ShortViewMap;
