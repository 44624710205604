import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { Link, useParams } from "react-router-dom";
import { routeLocationsEnum } from "../../Router/Router";
import Carousel from "react-material-ui-carousel";
import {
  linkStyles,
  photoMapBoxStyles,
  tableBodyStyles,
  tableHeadStyles,
  tableStyle,
} from "./styles";
import { getCemeteryFromStore } from "../../store/reducers/CemeteryReducer/actions";
import ShortViewMap from "../shortViewMap/shortViewMap";
import { getPersonFromStore } from "../../store/reducers/PeopleReducer/actions";
import {
  getPeopleToStore,
  setSelectedPolygon,
} from "../../store/reducers/GraveReducer/actions";
import { useTranslation } from "react-i18next";
import i18n from "../../utils/i18n";

const PersonPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { selectedCemetery } = useAppSelector((state) => state.cemeteryReducer);
  const { peopleDates } = useAppSelector((state) => state.graveReducer);
  const { selectedPerson } = useAppSelector((state) => state.peopleReducer);
  const [isPersonLoaded, setIsPersonLoaded] = useState(false);
  
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [mapModalOpen, setMapModalOpen] = useState(false);
  
  const handleModalOpenClose = () => {
    setMapModalOpen((prev) => !prev);
  };
  const translations = i18n.t("personPage", { returnObjects: true }) as {
    [key: string]: string;
  };
  useEffect(() => {
    if (id) {
      dispatch(getPersonFromStore(Number(id)))
        .then(() => setIsPersonLoaded(true)) // Предполагается, что действие возвращает Promise
        .catch((error) => console.error("Failed to load person data", error));
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (isPersonLoaded && peopleDates?.cemeteryId) {
      dispatch(getCemeteryFromStore(peopleDates.cemeteryId));
    }
  }, [isPersonLoaded, peopleDates?.cemeteryId, dispatch]);
  useEffect(() => {
    if (selectedPerson && selectedPerson.graveId) {
      dispatch(getPeopleToStore(selectedPerson.graveId));
      dispatch(setSelectedPolygon(selectedPerson.graveId));
    }
  }, [id, selectedPerson?.graveId, selectedPerson, dispatch]);
 
  if (!selectedPerson) {
    return null;
  }


  return (
    <Box sx={{ marginBottom: "45px", width: "100%", height: "autos" }}>
      <Box sx={tableStyle}>
        <Link
          to={`/${i18n.language}${routeLocationsEnum.placePage}/${selectedPerson.graveId}`}
          style={linkStyles}
        >
          {t(translations.backToGrave)}
        </Link>
        <Box
          sx={{
            fontSize: "40px",
            color: "#fff",
            textAlign: "center",
            marginBottom: "45px",
            fontWeight: "700",
          }}
        >
          {selectedCemetery?.title}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            marginBottom: "45px",
          }}
        >
          <Table sx={{ width: "55%", height: "auto" }}>
            <TableHead>
              <TableRow>
                <TableCell sx={tableHeadStyles} />
                <TableCell sx={tableHeadStyles}>
                  {t(translations.infoAbout)}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell sx={tableBodyStyles}>
                  {" "}
                  {t(translations.nameInfo)}
                </TableCell>
                <TableCell sx={tableBodyStyles}>
                  {selectedPerson.fullName}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={tableBodyStyles}>
                  {" "}
                  {t(translations.birthInfo)}
                </TableCell>
                <TableCell sx={tableBodyStyles}>
                  {selectedPerson.birthDate}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={tableBodyStyles}>
                  {t(translations.deathInfo)}
                </TableCell>
                <TableCell sx={tableBodyStyles}>
                  {selectedPerson.deathDate}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box sx={photoMapBoxStyles}>
            <Box sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
            <Carousel
                sx={{ width: "100%", height: "auto", overflow: "hidden" }}
                animation="slide"
                autoPlay={false}
              >
                {peopleDates &&
                  peopleDates.photos?.map((photo) => (
                    <Box
                      key={photo.key}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: "100%", height: "12rem" }}
                    >
                      <img
                        src={photo.url}
                        style={{ height: "100%", width: "100%",   borderRadius: "10px",
                      }}
                        alt={photo.key}
                      />
                    </Box>
                  ))}
              </Carousel>
            </Box>
          </Box>
        </Box>
      </Box>
      <ShortViewMap
        isMapModalOpen={mapModalOpen}
        onClick={handleModalOpenClose}
      />
    </Box>
  );
};

export default PersonPage;
