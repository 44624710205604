import { Reducer } from "redux";
import { CemeteryList } from "../../../models/ICemeteryMapProps";
import { CemeteryTypeEnum } from "./actionsTypes";

type CemeteryReducerType = {
  cemeteryList: CemeteryList[];
  selectedCemetery: CemeteryList | null;
  count: number;
  currentPage: number;
  titles: CemeteryList[];
};

const defaultState: CemeteryReducerType = {
  cemeteryList: [],
  selectedCemetery: null,
  count: 1,
  currentPage: 1,
  titles: [],
};

const cemeteryReducer: Reducer<CemeteryReducerType> = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case CemeteryTypeEnum.GET_CEMETERY_LIST:
      return { ...state, cemeteryList: action.payload };
    case CemeteryTypeEnum.SET_CEMETERY:
      return { ...state, selectedCemetery: action.payload };
    case CemeteryTypeEnum.SET_PAGINATION_DATA:
      return {
        ...state,
        count: action.payload.count,
        currentPage: action.payload.currentPage,
      };
    case CemeteryTypeEnum.SET_ALL_TITLES:
      return { ...state, titles: action.payload };
    default:
      return state;
  }
};

export default cemeteryReducer;
