import React, { useEffect, useState } from "react";
import { MapContainer, Polygon, TileLayer } from "react-leaflet";
import "./Styles.css";
import { CemeteryCoordinatesProps } from "../../models/ICemeteryMapProps";
import { getCemeteryFromStore } from "../../store/reducers/CemeteryReducer/actions";
import { useAppDispatch, useAppSelector } from "../../store/store";
import ModalWindowOfPersonDates from "../ModalWindowOfPersonDates/ModalWindowOfPersonDates";
import { Box } from "@mui/material";
import { CRS, LatLngTuple } from "leaflet";
import { calcCoordinates } from "../../utils/coordinateUtils";
import { useLocation, useParams } from "react-router-dom";
import {
  getCoordinatesToStore,
  getPeopleToStore,
  setSelectedPolygon,
} from "../../store/reducers/GraveReducer/actions";
import i18n from "../../utils/i18n";
import UpdateMapCenter from "../shortViewMap/mapUdater/mapUpdater";

const CemeteryMap: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const { selectedCemetery } = useAppSelector((state) => state.cemeteryReducer);
  const { coordinatesValues, peopleDates, selectedPolygonId } = useAppSelector(
    (state) => state.graveReducer
  );
  const dispatch = useAppDispatch();
  const [clickPolygon, setClickPolygon] = useState<number | null>(null);
  const [modalOpen, setModalOpen] = useState(false);
  const { latitude, longitude } = selectedCemetery?.coordinates || {
    latitude: 0,
    longitude: 0,
  };

  const handlePolygonClick = (cemeteryData: CemeteryCoordinatesProps) => {
    dispatch(setSelectedPolygon(cemeteryData.id));
    setModalOpen(true);
  };

  const closeModal = () => {
    setClickPolygon(null);
    setModalOpen(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const pathSegments = location.pathname.split('/');
        const languageInUrl = pathSegments[1];
  
        if (languageInUrl && i18n.language !== languageInUrl) {
          await i18n.changeLanguage(languageInUrl);
        }
  
        await dispatch(getCemeteryFromStore(Number(id)));
        await dispatch(getCoordinatesToStore(Number(id)));
  
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [dispatch, id, location.pathname]);
  

  useEffect(() => {
    if (selectedPolygonId) {
      dispatch(getPeopleToStore(selectedPolygonId));
    }
  }, [dispatch, selectedPolygonId, id]);
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: ".1rem auto",
      }}
    >
      {latitude && longitude && coordinatesValues && (
        <MapContainer
          center={[latitude, longitude]}
          zoom={16.2}
          scrollWheelZoom={true}
          style={{ width: "920px", height: "750px" }}
          maxZoom={21}
          crs={CRS.EPSG3857}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            maxNativeZoom={19}
            maxZoom={21}
          />
          {coordinatesValues.map((cemeteryData: CemeteryCoordinatesProps) => {
            const rectangleCoordinates = calcCoordinates(
              cemeteryData.coordinates
            );
            const latLngCoordinates: LatLngTuple[] = rectangleCoordinates.map(
              (coord) => [coord.latitude, coord.longitude]
            );

            return (
              <Polygon
                key={cemeteryData.id}
                positions={latLngCoordinates}
                pathOptions={{
                  color: clickPolygon === cemeteryData.id ? "#FFFF00" : "#fff",
                  weight: 2,
                }}
                eventHandlers={{
                  mouseover: () => setClickPolygon(cemeteryData.id),
                  mouseout: () => setClickPolygon(null),
                  click: () => handlePolygonClick(cemeteryData),
                }}
              />
            );
          })}
          <UpdateMapCenter center={[latitude, longitude]} />
        </MapContainer>
      )}

      {peopleDates && (
        <ModalWindowOfPersonDates
          open={modalOpen}
          onClose={closeModal}
          id={peopleDates.id}
          cemeteryId={selectedCemetery && selectedCemetery.id}
          persons={peopleDates.persons}
          photos={peopleDates.photos}
        />
      )}
    </Box>
  );
};

export default CemeteryMap;
