import { Dispatch } from "react";
import { People, PeopleForSearch } from "../../../models/ICemeteryMapProps";
import { PeopleTypeEnum } from "./actionsTypes";

import { AnyAction } from "redux";

import {
  getPeopleForSearch,
  getPersonById,
} from "../../../api/services/personService/services";

export const setSelectedPerson = (personDates: People | null) => {
  return { type: PeopleTypeEnum.SET_SELECTED_PERSON, payload: personDates };
};

export const setPeopleForSearch = (people: PeopleForSearch[]) => {
  return { type: PeopleTypeEnum.GET_PEOPLE_FOR_SEARCH, payload: people };
};

export const getPersonFromStore = (personId: number) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const [dataPerson] = await Promise.all([getPersonById(personId)]);
    if (dataPerson) {
      dispatch(setSelectedPerson(dataPerson));
    }
  };
};

export const getPeopleForSearchToStore = (cemeteryId:number, fullName: string) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const [dataPeopleForSearch] = await Promise.all([getPeopleForSearch(cemeteryId, fullName)]);
    dispatch(setPeopleForSearch(dataPeopleForSearch));
  };
};
