import React, { useEffect, useRef } from "react";
import { useMap } from "react-leaflet";

const UpdateMapCenter: React.FC<{ center: [number, number] }> = ({ center }) => {
  const map = useMap();
  const prevCenterRef = useRef<[number, number]>([0, 0]); 
  useEffect(() => {
    if (prevCenterRef.current[0] !== center[0] || prevCenterRef.current[1] !== center[1]) {
      map.setView(center, map.getZoom()); 
      prevCenterRef.current = center; 
    }
  }, [center, map]); 

  return null;
};

export default UpdateMapCenter;
