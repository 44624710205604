export const tableStyle = {
  display: "flex",
  flexDirection: "column",
  width: "58rem",
  alignItems: "center",
  justifyContent: "center",
  margin: "5rem auto",
  marginBottom: "45px",
};

export const linkStyles = {
  fontStyle: "italic, sans-serif",
  color: "#f1c40f",
  fontSize: "18px",
  height: "50px",
};

export const tableHeadStyles = {
  color: "#fff",
  fontSize: "15px",
  fontStyle: "arial, sans-serif",
  background: "#2b343c",
  textAlign: "center",
  border: "1px solid #000",
};

export const tableBodyStyles = {
  color: "#fff",
  fontSize: "14px",
  fontStyle: "arial, sans-serif",
  background: "#47525c",
  textAlign: "center",
};

export const photoMapBoxStyles = {
  display: "flex",
  flexDirection: "column",
  width: "360px",
  marginLeft: "auto",
};

export const titleStyle = {
  color: "#fff",
  fontStyle: "arial, sans-serif",
  fontSize: "20px",
  marginBottom: "25px",
};

export const mapBtnStyles = {
  color: "#fff",
  fontSize: "18px",
  fontStyle: "arial, sans-serif",
  marginTop: "10px",
  marginBottom: "25px",
  "&:hover": {
    color: "#f1c40f",
    cursor: "pointer",
  },
};

export const orderServicesBlockStyles = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  marginBottom: "40px",
};
