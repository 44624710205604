// import { Coordinates } from "../models/ICemeteryMapProps";

// const rotateCoordinates = (
//   point: Coordinates,
//   center: Coordinates,
//   angle: number
// ): Coordinates => {
//   const x = point[0] - center[0];
//   const y = point[1] - center[1];

//   const rotatedX = x * Math.cos(angle) - y * Math.sin(angle);
//   const rotatedY = x * Math.sin(angle) + y * Math.cos(angle);

//   return [rotatedX + center[0], rotatedY + center[1]];
// };

// const calcCoordinates = (center: Coordinates): Coordinates[] => {
//   const meterOffsetX = 0.00003;
//   const meterOffsetY = 0.00001;
//   const rotatedPoints: Coordinates[] = [];

//   const topLeft: Coordinates = [
//     center[0] - meterOffsetX+0.00001,
//     center[1] - meterOffsetY,
//   ];
//   const topRight: Coordinates = [
//     center[0] + meterOffsetX+0.00001,
//     center[1] - meterOffsetY,
//   ];
//   const bottomRight: Coordinates = [
//     center[0] + meterOffsetX,
//     center[1] + meterOffsetY,
//   ];
//   const bottomLeft: Coordinates = [
//     center[0] - meterOffsetX,
//     center[1] + meterOffsetY,
//   ];

//   const rotationAngle = (107 * Math.PI) / 180;

//   rotatedPoints.push(rotateCoordinates(topLeft, center, rotationAngle));
//   rotatedPoints.push(rotateCoordinates(topRight, center, rotationAngle));
//   rotatedPoints.push(rotateCoordinates(bottomRight, center, rotationAngle));
//   rotatedPoints.push(rotateCoordinates(bottomLeft, center, rotationAngle));

//   return rotatedPoints;
// };

// export { rotateCoordinates, calcCoordinates };
import { Coordinates } from "../models/ICemeteryMapProps";

const rotateCoordinates = (
  point: Coordinates,
  center: Coordinates,
  angle: number
): Coordinates => {
  const x = point.latitude - center.latitude;
  const y = point.longitude - center.longitude;

  const rotatedX = x * Math.cos(angle) - y * Math.sin(angle);
  const rotatedY = x * Math.sin(angle) + y * Math.cos(angle);

  return {
    latitude: rotatedX + center.latitude,
    longitude: rotatedY + center.longitude,
  };
};

const calcCoordinates = (center: Coordinates): Coordinates[] => {
  const meterOffsetX = 0.00003;
  const meterOffsetY = 0.00001;
  const rotatedPoints: Coordinates[] = [];

  const topLeft: Coordinates = {
    latitude: center.latitude - meterOffsetX + 0.00001,
    longitude: center.longitude - meterOffsetY,
  };
  const topRight: Coordinates = {
    latitude: center.latitude + meterOffsetX + 0.00001,
    longitude: center.longitude - meterOffsetY,
  };
  const bottomRight: Coordinates = {
    latitude: center.latitude + meterOffsetX,
    longitude: center.longitude + meterOffsetY,
  };
  const bottomLeft: Coordinates = {
    latitude: center.latitude - meterOffsetX,
    longitude: center.longitude + meterOffsetY,
  };

  const rotationAngle = (107 * Math.PI) / 180;

  rotatedPoints.push(rotateCoordinates(topLeft, center, rotationAngle));
  rotatedPoints.push(rotateCoordinates(topRight, center, rotationAngle));
  rotatedPoints.push(rotateCoordinates(bottomRight, center, rotationAngle));
  rotatedPoints.push(rotateCoordinates(bottomLeft, center, rotationAngle));

  return rotatedPoints;
};

export { rotateCoordinates, calcCoordinates };
