import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
  Autocomplete,
  Box,
  Button,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
import { routeLocationsEnum } from "../../Router/Router";
import {
  btnStyles,
  clearIconStyles,
  filterContainerStyles,
  filterFieldContainerStyles,
  filterLabelStyles,
  filterStyles,
  filterTitleStyles,
  searchFilterStyles,
  tableRowStyles,
} from "./styles";
import {
  getPeopleForSearchToStore,
  getPersonFromStore,
  setPeopleForSearch,
  setSelectedPerson,
} from "../../store/reducers/PeopleReducer/actions";
import {
  getCemeteryFromStore,
  setCemetery,
  setCemeteryTitlesToStore,
} from "../../store/reducers/CemeteryReducer/actions";

import { Formik, Form, useFormik, Field } from "formik";
import * as Yup from "yup";
import { SearchPeopleType } from "./types";
import { useTranslation } from "react-i18next";
import i18n from "../../utils/i18n";
import { CemeteryList } from "../../models/ICemeteryMapProps";

const PersonSearch: React.FC = () => {
  const { peopleForSearch } = useAppSelector((state) => state.peopleReducer);
  const { selectedCemetery } = useAppSelector((state) => state.cemeteryReducer);
  const { titles } = useAppSelector((state) => state.cemeteryReducer);
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [showNoResults, setShowNoResults] = useState<boolean>(false);
  const [searchCount, setSearchCount] = useState<number>(0);

  const navigate = useNavigate();
  const validationSchema = Yup.object({
    searchName: Yup.string(),
    searchGrave: Yup.number()
      .typeError("Grave must be a number")
      .min(1, "Grave must be greater than or equal to 1"),
  });

  const intialFormikValues: SearchPeopleType = {
    fullName: "",
    cemeteryId: "",
  };

  const handleSubmit = () => {
    const { fullName, cemeteryId } = formik.values;
    dispatch(getPeopleForSearchToStore(Number(cemeteryId), fullName));
    setShowNoResults(true);
  };
  const handleTextFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(e);
  };

  const handleCemeteryChange = (
    _event: React.ChangeEvent<{}>,
    newValue: CemeteryList | null
  ) => {
    formik.setFieldValue("cemeteryId", newValue?.id || "");
    if (newValue === null) {
      handleClear();
    }
  };

  const handleClear = () => {
    formik.resetForm({ values: { fullName: "", cemeteryId: "" } });
    dispatch(setPeopleForSearch([]));
    dispatch(setSelectedPerson(null));
    setShowNoResults(false);
  };
  const formik = useFormik({
    validateOnMount: true,
    initialValues: intialFormikValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });

  const handleFullInformation = (id: number) => {
    dispatch(getPersonFromStore(id));
    dispatch(setSelectedPerson(null));
    dispatch(setCemetery(null))
    navigate(`/${i18n.language}${routeLocationsEnum.personPage}/${id}`);
  };

  const translations = i18n.t("personSearch", { returnObjects: true }) as {
    [key: string]: string;
  };

  useEffect(() => {
    dispatch(setPeopleForSearch([]));
  }, [dispatch]);

  useEffect(() => {
    setSearchCount(peopleForSearch.length);
    if (peopleForSearch) {
      peopleForSearch.forEach((person) => {
        const { cemeteryId } = person.grave;
        dispatch(getCemeteryFromStore(cemeteryId));
      });
    }
  }, [dispatch, peopleForSearch]);
  useEffect(() => {
    const pathSegments = window.location.pathname.split("/");
    const languageInUrl = pathSegments[1];
    if (languageInUrl && i18n.language !== languageInUrl) {
      i18n.changeLanguage(languageInUrl);
    }
  }, []);

  useEffect(() => {
    dispatch(setCemeteryTitlesToStore());
  }, [dispatch]);

  return (
    <Box sx={searchFilterStyles}>
      <Box sx={filterContainerStyles}>
        <Box sx={filterTitleStyles}>
          {peopleForSearch && peopleForSearch.length > 0 ? (
            <>
              {t(translations.searchResults)} ({searchCount})
            </>
          ) : (
            <>{t(translations.searchCriteria)}</>
          )}
        </Box>
        <Formik
          initialValues={{ fullName: "", cemeteryId: "" }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          validateOnMount={false}
        >
          <Form style={{ width: "80%" }}>
            <Box sx={filterFieldContainerStyles}>
              <Box sx={filterStyles}>
                <Box sx={filterLabelStyles}>
                  {t(translations.selectCemetery)}
                </Box>
                <Autocomplete
                  value={
                    titles.find(
                      (option) => option.id === formik.values.cemeteryId
                    ) || null
                  }
                  onChange={handleCemeteryChange}
                  options={titles}
                  getOptionLabel={(option) => option.title}
                  renderOption={(props, option) => (
                    <li {...props} key={option.id}>
                      {option.title}
                    </li>
                  )}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value?.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t(translations.selectCemetery)}
                      placeholder={t(translations.selectCemetery)}
                      variant="outlined"
                    />
                  )}
                  clearOnEscape={true}
                />
              </Box>
              <Box sx={filterStyles}>
                <Box sx={filterLabelStyles}>{t(translations.nameSurname)}</Box>
                <Field
                  label={t(translations.findDeceased)}
                  placeholder={t(translations.findDeceased)}
                  type="text"
                  id="fullName"
                  name="fullName"
                  as={TextField}
                  onChange={handleTextFieldChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.fullName}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {formik.values.fullName ? (
                          <ClearIcon
                            sx={clearIconStyles}
                            onClick={() => handleClear()}
                          />
                        ) : (
                          <SearchIcon />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            </Box>
            <Button
              type="submit"
              disabled={!formik.values.fullName && !formik.values.cemeteryId}
              sx={btnStyles}
            >
              {t(translations.findBtn)}
            </Button>
          </Form>
        </Formik>
      </Box>
      <TableContainer component={Box} sx={{ width: "100%", overflowX: "auto" }}>
        <Table sx={{ width: "100%" }}>
          <TableBody>
            {peopleForSearch &&
              peopleForSearch.map((person) => (
                <TableRow
                  key={person.id}
                  sx={tableRowStyles}
                  onClick={() => handleFullInformation(person.id)}
                >
                  <TableCell>{person.fullName}</TableCell>
                  <TableCell>
                    {person.birthDate} - {person.deathDate}
                  </TableCell>
                  <TableCell>{selectedCemetery?.title}</TableCell>
                </TableRow>
              ))}
            {showNoResults &&
              peopleForSearch &&
              peopleForSearch.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    {t(translations.noResults)}
                  </TableCell>
                </TableRow>
              )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default PersonSearch;
