import { useAppDispatch, useAppSelector } from "../../store/store";
import { limit } from "../../constants/constants";
import { getCemeteryListToStore, setPaginationData } from "../../store/reducers/CemeteryReducer/actions";
import { Pagination, Stack } from "@mui/material";

const PaginationComponent: React.FC = () => {
  const { count, currentPage } = useAppSelector(
    (state) => state.cemeteryReducer
  );
  const dispatch = useAppDispatch();
  const handlePageChange = async (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    dispatch(setPaginationData(count, value));
    await dispatch(getCemeteryListToStore(value));
  };
  return (
    <Stack spacing={2} sx={{marginTop:"10%", marginBottom:"2%"}}>
      <Pagination
        count={Math.ceil(count / limit)}
        page={currentPage}
        onChange={handlePageChange}
        sx={{
            "& .MuiPaginationItem-root": {
              borderRadius: "50%",
              color: "#fff",
              fontSize:  "16px",
              backgroundColor: "transparent",
            },
            "& .MuiPaginationItem-root:hover": {
              color: "#000",
              backgroundColor: "#fff",
            },
            "& .MuiPaginationItem-page.Mui-selected": {
                color: "#000",
                backgroundColor: "#fff",
            },
          }}
      />
    </Stack>
  );
};

export default PaginationComponent;
