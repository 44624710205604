import React from "react";
import { Box, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { buttonBlock, closeIconStyles, openMapStyles } from "./styles";
import { useTranslation } from "react-i18next";
import i18n from "../../../utils/i18n";

interface MapControlsProps {
  toggleFullScreen: () => void;
  isFullScreen: boolean;
  positionStyles?: React.CSSProperties;
}

const MapControls: React.FC<MapControlsProps> = ({
  toggleFullScreen,
  isFullScreen,
  positionStyles = {},
}) => {
  const { t } = useTranslation();

  const translations = i18n.t("mapControls", { returnObjects: true }) as {
    [key: string]: string;
  };
  const handleToggleFullScreen = () => {
    toggleFullScreen();
  };

  const closeIcon = (
    <IconButton onClick={handleToggleFullScreen} sx={closeIconStyles}>
      <CloseIcon />
    </IconButton>
  );

  const fullscreenIcon = (
    <Box onClick={handleToggleFullScreen}>
      <Box sx={openMapStyles}> {t(translations.openMapBtn)}</Box>
    </Box>
  );

  return (
    <Box sx={{ ...buttonBlock, ...positionStyles }}>
      <Box sx={buttonBlock}>{isFullScreen ? closeIcon : fullscreenIcon}</Box>
    </Box>
  );
};

export default MapControls;
