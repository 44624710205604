import React, { useEffect } from "react";
import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../store/store";
import {
  getCemeteryListToStore,
  setCemetery,
} from "../../store/reducers/CemeteryReducer/actions";
import { useNavigate, useLocation } from "react-router-dom";
import { routeLocationsEnum } from "../../Router/Router";
import { getCoordinatesToStore } from "../../store/reducers/GraveReducer/actions";
import PaginationComponent from "../Pagination/Pagination";
import { useTranslation } from "react-i18next";
import Carousel from "react-material-ui-carousel"; 
import { cemeteryListStyles, listItemStyles, titleStyles } from "./styles";

const ListOfCemetery: React.FC = () => {
  const { i18n } = useTranslation();
  const { cemeteryList } = useAppSelector((state) => state.cemeteryReducer);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleNavigateToMap = async (id: number) => {
    await dispatch(getCoordinatesToStore(id));
    dispatch(setCemetery(null));
    navigate(`${location.pathname}${routeLocationsEnum.mainMap}/${id}`);
  };

  useEffect(() => {
    const fetchData = async () => {
      const pathSegments = location.pathname.split("/");
      const languageInUrl = pathSegments[1];
      if (languageInUrl && i18n.language !== languageInUrl) {
        i18n.changeLanguage(languageInUrl);
      }
      await dispatch(getCemeteryListToStore(1));
    };

    fetchData();
  }, [dispatch, i18n, location.pathname]);

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box sx={cemeteryListStyles}>
        {cemeteryList &&
          cemeteryList.map((list) => (
            <Box key={list.id} sx={listItemStyles}>
              <Carousel
                sx={{ width: "45%", height: "12rem", overflow: "hidden" }}
                animation="slide"
                autoPlay={false}
              >
                {list.photos?.map((photo) => (
                  <Box
                    key={photo.key}
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{ width: "100%", height: "12rem" }}
                  >
                    <img
                      src={photo.url}
                      style={{ height: "100%", width: "100%",   borderRadius: "10px",
                    }}
                      alt={list.title}
                    />
                  </Box>
                ))}
              </Carousel>

              <Box
                sx={titleStyles}
                onClick={() => {
                  handleNavigateToMap(list.id);
                }}
              >
                {list.title}
              </Box>
            </Box>
          ))}
      </Box>
      <PaginationComponent />
    </Box>
  );
};

export default ListOfCemetery;
