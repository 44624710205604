import {
  Box,
  Modal,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import {
  ModalWindowStyles,
  StackStyles,
  TableBodyStyles,
  LinkStyles,
  PhotoBoxStyles,
  PhotoContainerStyles,
  closeBtnStyles,
} from "./styles";
import HeadOfTable from "./TableHead/HeadOfTable";
import { routeLocationsEnum } from "../../Router/Router";
import { useTranslation } from "react-i18next";
import i18n from "../../utils/i18n";
import { ServerPhotos } from "../../models/ICemeteryMapProps";

type ModalWindowOfPersonDatesType = {
  open: boolean;
  cemeteryId: number | null;
  onClose: () => void;
  id: number;
  persons: {
    id: number;
    fullName: string;
    birthDate: string;
    deathDate: string;
  }[];
  photos: ServerPhotos[];
};

const ModalWindowOfPersonDates: React.FC<ModalWindowOfPersonDatesType> = (
  props
) => {
  const { open, onClose, persons, photos } = props;
  const { t } = useTranslation();
  const location = useLocation();

  const translations = i18n.t("modalWindowOfPersonDates", {
    returnObjects: true,
  }) as {
    [key: string]: string;
  };

  const getUrlWithLanguage = (url: string) => {
    return `/${i18n.language}${url}`;
  };

  const isOnPlacePage =
    location.pathname ===
    getUrlWithLanguage(`${routeLocationsEnum.mainMap}/${props.cemeteryId}`);

  return (
    <Modal open={open} sx={ModalWindowStyles}>
      <Stack sx={StackStyles}>
        <Box onClick={onClose} sx={closeBtnStyles}>
          <CloseIcon
            sx={{ color: "#fff", cursor: "pointer", fontSize: "25px" }}
          />
        </Box>
        <Table sx={{ marginBottom: "1rem" }}>
          <HeadOfTable />
          <TableBody>
            {persons.map((personMap) => (
              <TableRow key={personMap.id}>
                <TableCell sx={TableBodyStyles}>{personMap.fullName}</TableCell>
                <TableCell sx={TableBodyStyles}>
                  {personMap.birthDate}
                </TableCell>
                <TableCell sx={TableBodyStyles}>
                  {personMap.deathDate}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Box sx={PhotoContainerStyles}>
          {photos?.map((photo) => (
            <Box key={photo.key} sx={PhotoBoxStyles}>
              <img
                src={photo.url}
                alt={photo.key}
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
              />
            </Box>
          ))}
        </Box>
        {isOnPlacePage && (
          <Link
            to={getUrlWithLanguage(
              `${routeLocationsEnum.placePage}/${props.id}`
            )}
            style={LinkStyles}
          >
            {t(translations.link)}
          </Link>
        )}
      </Stack>
    </Modal>
  );
};

export default ModalWindowOfPersonDates;
