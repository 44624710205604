import { Route, Routes, Navigate } from "react-router-dom";
import { routeLocationsEnum } from "./Router";
import PeopleInfoPage from "./pages/PeopleInfoPage";
import PersonInfoPage from "./pages/PersonInfoPage";
import MapPage from "./pages/MapPage";
import CemeteryListPage from "./pages/CemeteryListPage";
import PersonSearchPage from "./pages/PersonSearchPage";

const RouterRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path={`/:lng${routeLocationsEnum.cemeteryList}`}
        element={<CemeteryListPage />}
      />
      <Route
        path={`/:lng${routeLocationsEnum.mainMap}/:id`}
        element={<MapPage />}
      />
      <Route
        path={`/:lng${routeLocationsEnum.personSearch}`}
        element={<PersonSearchPage />}
      />
      <Route
        path={`/:lng${routeLocationsEnum.placePage}/:id`}
        element={<PeopleInfoPage />}
      />
      <Route
        path={`/:lng${routeLocationsEnum.personPage}/:id`}
        element={<PersonInfoPage />}
      />
      <Route path="*" element={<Navigate to="/en" />} />
    </Routes>
  );
};

export default RouterRoutes;
