
export const cemeteryListStyles = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "20px",
  margin: "5% auto",
  width: "100%",
};

export const listItemStyles = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap:"20px",
  width: "45%",
   height: "12rem",
  justifyContent: "space-between",
  border: "none",
  borderRadius: "10px",
  backgroundColor: "#fff",
  boxShadow: "0 .5rem 1.5rem rgba(0, 0, 0, 0.1)",
  cursor:"pointer"
};

export const titleStyles = {
  display: "flex",
  justifyContent: "left",
  alignItems: "center",
  margin: "auto",
  width: "55%",
  height: "100%",
  fontSize: "20px",
  fontWeight: "700",
};
