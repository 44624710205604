import { TableCell, TableHead, TableRow } from "@mui/material";
import { tableHeadStyles } from "../styles";
import { useTranslation } from "react-i18next";
import i18n from "../../../utils/i18n";

const HeadOfTable: React.FC = () => {
  const { t } = useTranslation();
  const translations = i18n.t("modalWindowOfPersonDates", {
    returnObjects: true,
  }) as {
    [key: string]: string;
  };
  return (
    <TableHead>
      <TableRow>
        <TableCell sx={tableHeadStyles}>
          {t(translations.nameSurnameHead)}
        </TableCell>
        <TableCell sx={tableHeadStyles}>{t(translations.birthDate)}</TableCell>
        <TableCell sx={tableHeadStyles}>{t(translations.deathDate)}</TableCell>
        <TableCell sx={tableHeadStyles} />
      </TableRow>
    </TableHead>
  );
};

export default HeadOfTable;
