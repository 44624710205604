import { Reducer } from "redux";
import { People, PeopleForSearch } from "../../../models/ICemeteryMapProps";
import { PeopleTypeEnum } from "./actionsTypes";

type CemeteryReducerType = {
  selectedPerson: People | null;
  peopleForSearch: PeopleForSearch[];
};

const defaultState: CemeteryReducerType = {
  selectedPerson: null, 
  peopleForSearch: [], 
};

const peopleReducer: Reducer<CemeteryReducerType> = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case PeopleTypeEnum.SET_SELECTED_PERSON:
      return { ...state, selectedPerson: action.payload };

    case PeopleTypeEnum.GET_PEOPLE_FOR_SEARCH:
      return { ...state, peopleForSearch: action.payload };

    default:
      return state;
  }
};

export default peopleReducer;
