import { Dispatch } from "react";
import { CemeteryList } from "../../../models/ICemeteryMapProps";
import { CemeteryTypeEnum } from "./actionsTypes";
import {
  getCemeteryCoordinatesById,
  getCemeteryList,
  getCemeteryTitleList,
} from "../../../api/services/cemeteryService/services";
import { AnyAction } from "redux";

export const setCemeteryList = (list: CemeteryList[]) => {
  return { type: CemeteryTypeEnum.GET_CEMETERY_LIST, payload: list };
};

export const setCemetery = (cemetery: CemeteryList | null ) => {

  return { type: CemeteryTypeEnum.SET_CEMETERY, payload: cemetery };
};

export const setPaginationData = (count: number, currentPage: number) => {
  return {
    type: CemeteryTypeEnum.SET_PAGINATION_DATA,
    payload: {count, currentPage}
  }
}

export const setAllTitles = (titles: CemeteryList[]) => {
  return {
    type: CemeteryTypeEnum.SET_ALL_TITLES,
    payload: titles,
  };
};

export const getCemeteryListToStore = (page:number = 1 ) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const [dataList] = await Promise.all([getCemeteryList(page)]);
    const {count, data} = dataList
    dispatch(setCemeteryList(data));
    dispatch(setPaginationData(count, page));
  };
};

export const getCemeteryFromStore = (cemeteryId: number) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const [dataCemetery] = await Promise.all([
      getCemeteryCoordinatesById(cemeteryId),
    ]);
    if (dataCemetery) {
      dispatch(setCemetery(dataCemetery));
    }
  };
};


export const setCemeteryTitlesToStore = () => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const [dataList] = await Promise.all([getCemeteryTitleList()]);
    dispatch(setAllTitles(dataList));
  };
};