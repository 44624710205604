export const searchFilterStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#fff",
  padding: "30px",
  width: "80%",
  height: "auto",
  margin: "5% auto",
  borderRadius: "10px",
};

export const filterContainerStyles = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: "5rem",
  width: "100%",
  marginBottom: "30px",
};

export const tableRowStyles = {
  width: "100%",
  cursor: "pointer",
  borderRadius: "10px",
  border: "none",
  transition: "background-color 0.3s ease-in-out",
  "&:hover": {
    backgroundColor: "#e5e2e2",
  },
};

export const filterTitleStyles = {
  fontSize: "50px",
  fontWeight: "700",
  color: "#000",
  textAlign: "center",
};

export const filterFieldContainerStyles = {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  gap: "20px",
  width: "100%",
  justifyContent: "center",
  height: "auto",
  marginBottom: "20px",
};

export const filterStyles = {
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: "20px",
};

export const clearIconStyles = {
  fontSize: "20px",
  color: "#000",
  cursor: "pointer",
};

export const filterLabelStyles = {
  fontSize: "20px",
  color: "#000",
  fontWeight: "700",
};

export const btnStyles = {
  fontSize: "16px",
  fontWeight: "700",
  color: "#000",
  border: "1px solid #808080",
  width: "5rem",
  height: "3rem",
  display: "flex",
  justifyContent: "center",
  margin: "auto",
};
