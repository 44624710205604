import i18n, { InitOptions } from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslation from "../locales/en/translation.json";
import ruTranslation from "../locales/ru/translation.json";
import lvTranslation from "../locales/lv/translation.json";
import { Resources } from "./i18nTypes";

const resources: Resources = {
  en: { translation: enTranslation },
  ru: { translation: ruTranslation },
  lv: { translation: lvTranslation },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
} as InitOptions);

export default i18n;
