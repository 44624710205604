export const tableStyle = {
  display: "flex",
  flexDirection: "column",
  width: "58rem",
  height: "auto",
  alignItems: "center",
  justifyContent: "center",
  margin: "5rem auto",
  marginBottom: "45px",
};

export const linkStyles = {
  fontStyle: "italic, sans-serif",
  color: "#f1c40f",
  fontSize: "18px",
  height: "50px",
};

export const tableHeadStyles = {
  color: "#fff",
  fontSize: "18px",
  fontStyle: "arial, sans-serif",
  background: "#2b343c",
  textAlign: "center",
  border: "1px solid #000",
};

export const tableBodyStyles = {
  color: "#fff",
  fontSize: "14px",
  fontStyle: "arial, sans-serif",
  background: "#47525c",
  textAlign: "center",
};

export const photoMapBoxStyles = {
  display: "flex",
  flexDirection: "column",
  width: "360px",
  marginLeft: "auto",
};

export const titleStyles = {
  fontSize: "35px",
  fontWeight: "400",
  marginBottom: "70px",
  color: "#fff",
  fontFamily: "arial, sans-serif",
};
