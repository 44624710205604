import React, { useEffect, useState } from "react";
import {
  Box,
  Table,
  TableCell,
  TableRow,
  Button,
  Skeleton,
  TableBody,
} from "@mui/material";
import { useAppSelector, useAppDispatch } from "../../store/store";
import { routeLocationsEnum } from "../../Router/Router";
import Carousel from "react-material-ui-carousel";
import {
  linkStyles,
  tableBodyStyles,
  tableStyle,
  titleStyle,
  orderServicesBlockStyles,
  photoMapBoxStyles,
} from "./styles";
import HeadOfTable from "./TableHead/HeadOfTable";
import { getCemeteryFromStore } from "../../store/reducers/CemeteryReducer/actions";
import { Link, useNavigate, useParams } from "react-router-dom";
import { People } from "../../models/ICemeteryMapProps";
import ShortViewMap from "../shortViewMap/shortViewMap";
import {
  getPeopleToStore,
  setSelectedPolygon,
} from "../../store/reducers/GraveReducer/actions";
import { setSelectedPerson } from "../../store/reducers/PeopleReducer/actions";
import { useTranslation } from "react-i18next";
import i18n from "../../utils/i18n";

const PeopleInfo: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const { t } = useTranslation();

  const translations = i18n.t("peopleInfo", { returnObjects: true }) as {
    [key: string]: string;
  };
  const { selectedCemetery } = useAppSelector((state) => state.cemeteryReducer);
  const { peopleDates } = useAppSelector((state) => state.graveReducer);
  const dispatch = useAppDispatch();
  const [mapModalOpen, setMapModalOpen] = useState(false);

  const navigate = useNavigate();

  const handleFullInformation = async (person: People) => {
    dispatch(setSelectedPerson(person));
    if (person.id) {
      navigate(
        `/${i18n.language}${routeLocationsEnum.personPage}/${person.id}`
      );
    }
  };

  const handleModalOpenClose = () => {
    setMapModalOpen((prev) => !prev);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getPeopleToStore(Number(id)));
        await dispatch(setSelectedPolygon(Number(id)));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [id, dispatch]);

  useEffect(() => {
    if (peopleDates) {
      dispatch(getCemeteryFromStore(peopleDates.cemeteryId));
    }
  }, [peopleDates?.cemeteryId, peopleDates, dispatch]);

  return (
    <Box sx={{ marginBottom: "45px" }}>
      <Box sx={tableStyle}>
        <Link
          to={`/${i18n.language}${routeLocationsEnum.mainMap}/${
            selectedCemetery && selectedCemetery.id
          }`}
          style={linkStyles}
        >
          {t(translations.backToMap)}
        </Link>
        <Box
          sx={{
            fontSize: "40px",
            color: "#fff",
            textAlign: "center",
            marginBottom: "45px",
            fontWeight: "700",
          }}
        >
          {selectedCemetery?.title}
        </Box>
        <Table sx={{ marginBottom: "45px" }}>
          <HeadOfTable />
          <TableBody>
            {peopleDates &&
              peopleDates.persons.map((person: People) => (
                <TableRow key={person.id}>
                  <TableCell sx={tableBodyStyles}>{person.fullName}</TableCell>
                  <TableCell sx={tableBodyStyles}>{person.birthDate}</TableCell>
                  <TableCell sx={tableBodyStyles}>{person.deathDate}</TableCell>
                  <TableCell sx={tableBodyStyles}>
                    <Button
                      sx={tableBodyStyles}
                      onClick={() => handleFullInformation(person)}
                    >
                      {t(translations.fullInfoBtn)}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
        <Box sx={orderServicesBlockStyles}>
          <Box>
            <Box sx={titleStyle}>{t(translations.orderServices)}</Box>
            <Skeleton
              variant="rectangular"
              width={520}
              height={80}
              sx={{ background: "#272626" }}
            />
          </Box>
          <Box sx={photoMapBoxStyles}>
            <Box sx={titleStyle}>{t(translations.photoOfGrave)}</Box>
            <Box sx={{ display: "flex", flexDirection: "row", gap: "5px" }}>
              <Carousel
                sx={{ width: "100%", height: "auto", overflow: "hidden" }}
                animation="slide"
                autoPlay={false}
              >
                {peopleDates &&
                  peopleDates.photos?.map((photo) => (
                    <Box
                      key={photo.key}
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{ width: "100%", height: "12rem" }}
                    >
                      <img
                        src={photo.url}
                        style={{
                          height: "100%",
                          width: "100%",
                          borderRadius: "10px",
                        }}
                        alt={photo.key}
                      />
                    </Box>
                  ))}
              </Carousel>
            </Box>
          </Box>
        </Box>
      </Box>
      <ShortViewMap
        isMapModalOpen={mapModalOpen}
        onClick={handleModalOpenClose}
      />
    </Box>
  );
};

export default PeopleInfo;
