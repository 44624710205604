import { Dispatch } from "react";
import {
  CemeteryCoordinatesProps,
  CemeteryProps,
} from "../../../models/ICemeteryMapProps";
import { GraveTypeEnum } from "./actionsTypes";

import { AnyAction } from "redux";
import {
  getGraveById,
  getCoordinates,
} from "../../../api/services/graveService/services";

export const setCoordinates = (coordinates: CemeteryCoordinatesProps[]) => {
  return { type: GraveTypeEnum.SET_COORDINATES, payload: coordinates }; 
};

export const setSelectedPolygon = (selectedPolygonId: number) => {
  return {
    type: GraveTypeEnum.SET_SELECTED_POLYGON,
    payload: selectedPolygonId, 
  };
};

export const getPeopleById = (people: CemeteryProps) => {
  return { type: GraveTypeEnum.GET_PEOPLE_INFO_BY_ID, payload: people }; 
};

export const getCoordinatesToStore = (cemeteryId: number) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const [dataCoordinates] = await Promise.all([getCoordinates(cemeteryId)]);
    dispatch(setCoordinates(dataCoordinates));
  };
};

export const getPeopleToStore = (selectedPolygonId: number) => {
  return async (dispatch: Dispatch<AnyAction>) => {
    const [dataPerson] = await Promise.all([getGraveById(selectedPolygonId)]);
    dispatch(getPeopleById(dataPerson));
  };
};
