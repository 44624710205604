export const buttonBlock = {
  display: "flex",
  zIndex: 1000,
};

export const closeIconStyles = {
  position: "absolute",
  top: "10px",
  right: "10px",
  background: "#fff",
  borderRadius: "5px",
  color: "#000",
  fontSize: "20px",
  padding: "5px",
  "&:hover": {
    background: "#fff",
  },
};

export const openMapStyles = {
  cursor: "pointer",
  textAlign: "center",
  color: "#000",
  background: "#fff",
  borderRadius: "5px",
  fontSize: "18px",
  fontWeight: "500",
  padding: "5px",
  width: "15%",
  height: "auto",
  position: "absolute",
  top: "50%",
  left: "43%",
};
