import { limit } from "../../../constants/constants";
import {
  CemeteryList,
  CemeteryListResponse,
  ServerPhotos,
} from "../../../models/ICemeteryMapProps";

export const getCemeteryList = async (
  page: number
): Promise<CemeteryListResponse> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/cemetery?page=${page}&limit=${limit}`
    );
    const responseData = await response.json();
    const cemeteryListData: CemeteryList[] = responseData.data.map(
      (cemetery: CemeteryList) => ({
        id: cemetery.id,
        title: cemetery.title,
        coordinates: cemetery.coordinates,
        photos: cemetery.photos
          ? cemetery.photos.map((photo: ServerPhotos) => ({
              key: photo.key,
              url: photo.url,
            }))
          : [],
      })
    );

    return {
      data: cemeteryListData,
      count: responseData.meta.count,
    };
  } catch (error) {
    console.error("Error", error);
    throw error;
  }
};

export const getCemeteryCoordinatesById = async (
  cemeteryId: number
): Promise<CemeteryList> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/cemetery/${cemeteryId}`
    );
    const responseData = await response.json();
    const { id, title, coordinates, photos } = responseData.data;

    const cemeteryListData: CemeteryList = {
      id,
      title,
      coordinates,
      photos
    };
    return cemeteryListData;
  } catch (error) {
    console.error("Error", error);
    throw error;
  }
};

export const getCemeteryTitleList = async (): Promise<CemeteryList[]> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/cemetery`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const responseData = await response.json();
    const cemeteryListData: CemeteryList[] = responseData.data.map(
      (cemetery: CemeteryList) => ({
        id: cemetery.id,
        title: cemetery.title,
      })
    );

    return cemeteryListData;
  } catch (error) {
    console.error("Error", error);
    throw error;
  }
};
