import { BrowserRouter } from "react-router-dom";
import RouterRoutes from "./RouterRoutes";
import { Suspense, lazy } from "react";

const Header = lazy(() => import("../components/Header/Header"));

export enum routeLocationsEnum {
  cemeteryList = "/",
  mainMap = "/map",
  placePage = "/placePage",
  personPage = "/person",
  personSearch = "/personSearch",
}

const Router: React.FC = () => {
  return (
    <BrowserRouter>
      <Suspense>
        <Header />
        <RouterRoutes />
      </Suspense>
    </BrowserRouter>
  );
};

export default Router;
