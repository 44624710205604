import {
  CemeteryCoordinatesProps,
  CemeteryProps,
} from "../../../models/ICemeteryMapProps";

export const getCoordinates = async (
  cemeteryId: number
): Promise<CemeteryCoordinatesProps[]> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/grave?cemeteryId=${cemeteryId}`);
    const responseData = await response.json();
    const coordinates: CemeteryCoordinatesProps[] = responseData.data.map(
      (props: CemeteryCoordinatesProps) => ({
        id: props.id,
        cemeteryId: props.cemeteryId,
        coordinates: props.coordinates,
      })
    );
    return coordinates;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getGraveById = async (id: number): Promise<CemeteryProps> => {
  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/grave/${id}`);

    const responseData = await response.json();
    const grave: CemeteryProps = {
      id: responseData.data.id,
      cemeteryId: responseData.data.cemeteryId,
      coordinates: responseData.data.coordinates,
      photos: responseData.data.photos,
      persons: responseData.data.persons || [],
    };
    return grave;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};
