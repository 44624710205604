import { People, PeopleForSearch } from "../../../models/ICemeteryMapProps";

export const getPersonById = async (personId: number): Promise<People> => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_BACKEND_URL}/person/${personId}`
    );
    const responseData = await response.json();

    const { id, fullName, birthDate, deathDate, graveId } = responseData.data;

    const person: People = {
      id,
      fullName,
      birthDate,
      deathDate,
      graveId,
    };

    return person;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

export const getPeopleForSearch = async (
  cemeteryId: number,
  fullName: string
): Promise<PeopleForSearch[]> => {
  try {
    let url = `${process.env.REACT_APP_BACKEND_URL}/person?`;

    if (cemeteryId !== 0) {
      url += `cemeteryId=${cemeteryId}&`;
    }

    if (fullName !== "") {
      url += `fullName=${fullName}`;
    }

    const response = await fetch(url);
    const responseData = await response.json();
    const SearchPeople: PeopleForSearch[] = responseData.data.map(
      (props: PeopleForSearch) => ({
        id: props.id,
        fullName: props.fullName,
        birthDate: props.birthDate,
        deathDate: props.deathDate,
        grave: {
          id: props.grave.id,
          cemeteryId: props.grave.cemeteryId,
          photos: props.grave.photos,
        },
      })
    );
    return SearchPeople;
  } catch (error) {
    console.error("Error", error);
    throw error;
  }
};