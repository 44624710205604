import { Reducer } from "redux";
import {
  CemeteryCoordinatesProps,
  CemeteryProps,
} from "../../../models/ICemeteryMapProps";
import { GraveTypeEnum } from "./actionsTypes";

type CemeteryReducerType = {
  selectedPolygonId: number | null;
  coordinatesValues: CemeteryCoordinatesProps[];
  peopleDates: CemeteryProps | null;
};

const defaultState: CemeteryReducerType = {
  selectedPolygonId: null,
  coordinatesValues: [],
  peopleDates: null,
};

const graveReducer: Reducer<CemeteryReducerType> = (
  state = defaultState,
  action
) => {
  switch (action.type) {
    case GraveTypeEnum.SET_SELECTED_POLYGON:
      return {
        ...state,
        selectedPolygonId: action.payload,
      };
    case GraveTypeEnum.SET_COORDINATES:
      return { ...state, coordinatesValues: action.payload };
    case GraveTypeEnum.GET_PEOPLE_INFO_BY_ID:
      return { ...state, peopleDates: action.payload };

    default:
      return state;
  }
};

export default graveReducer;
